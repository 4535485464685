<template>
  <div class="user-list">
    <div class="filter-container">
      <div class="brand-filter">
        <span>{{ brandName }}</span>
        <Dropdown style="margin-left: 20px" @on-click="onBrandItemClick">
          <Button type="primary">
            商家选择
            <Icon type="ios-arrow-down"></Icon>
          </Button>
          <DropdownMenu slot="list">
            <DropdownItem name="0" :selected="brandType == 0"
              >全部</DropdownItem
            >
            <DropdownItem
              :name="item.type"
              :selected="brandType == item.type"
              v-for="(item, index) in brandList"
              :key="index"
              >{{ item.name }}</DropdownItem
            >
          </DropdownMenu>
        </Dropdown>
      </div>
      <div class="search-container">
        <Input
          search
          enter-button
          placeholder="输入 用户名查询"
          @on-search="onSearch"
        />
      </div>
    </div>
    <Table
      :columns="userColumns"
      :data="userData"
      border
      :max-height="tableMaxHeight"
    >
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="primary"
          size="small"
          style="margin: 2px"
          @click="showAtiveRecord(row, index)"
          >激活记录</Button
        >
      </template>
    </Table>
    <div
      style="
        margin: 20px 12px;
        overflow: hidden;
        position: fixed;
        bottom: 10px;
        right: 10px;
        width: 100%;
      "
    >
      <div style="display: flex; flex-direction: row-reverse">
        <Page
          :total="totalCount"
          :current="currentPage"
          @on-change="changePage"
          :page-size="pageSize"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinDevice } from "@/util/mixin";
import Store from "@/store";

const brands = [];

export default {
  mixins: [mixinDevice],
  data() {
    let columnWidth = Store.state.deviceType == "mobile" ? 140 : "auto";
    return {
      userColumns: [
        {
          title: "用户名",
          key: "mobile",
          width: columnWidth,
        },
        {
          title: "注册日期",
          key: "registerTime",
          width: columnWidth,
        },
        {
          title: "账户类型",
          key: "accountType",
          width: columnWidth,
        },
        {
          title: "到期日期",
          key: "expireTime",
          width: columnWidth,
        },
        {
          title: "户型数量",
          key: "houseCount",
          width: columnWidth,
        },
        {
          title: "商家名称",
          key: "brandType",
          width: columnWidth,
        },
        {
          title: "操作",
          slot: "action",
          fixed: "right",
          width: "120",
          align: "center",
        },
      ],
      userData: [],
      currentPage: 1,
      totalCount: 1200,
      pageSize: 50,
      brandType: 0,
      brandList: brands,
    };
  },
  computed: {
    brandName() {
      if (this.brandType == 0) return "全部";
      this.brandList.forEach((item) => {
        if (item.type == this.brandType) {
          return item.name;
        }
      });
      return "未知";
    },
    tableMaxHeight() {
      return this.isMobile() ? 540 : 700;
    },
  },
  methods: {
    showAtiveRecord(row, index) {
      console.log("row : ", row, " index: ", index);
      this.$router.push({ name: "user-active-record" });
    },
    changePage(newPage) {
      this.currentPage = newPage;
      console.log("currentPage: ", this.currentPage);
    },
    onBrandItemClick(name) {
      console.log("name: ", name);
      this.brandType = parseInt(name);
    },
    onSearch(content) {
      console.log("search content: ", content);
    },
  },
  mounted() {
    this.columnWidth = this.isMobile() ? 140 : "auto";
  },
};
</script>

<style lang="less" scoped>
.user-list {
  width: 100%;
  padding: 12px;

  .filter-container {
    margin-bottom: 12px;
    padding: 12px 24px;
    border: 1px dashed green;
    display: flex;

    .search-container {
      margin-left: auto;
    }

    @media screen and (max-width: 750px) {
      
      flex-direction: column;
     
      .search-container {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
</style>